export type IValidationControllerProps<TValue = string> = {
  onValueChange(value: TValue): void;
  setValidityIndication(shouldShowValidityIndication: boolean): void;
};

export const getValidationControllerProps = <TValue = string>(
  updateProps: (partialProps: Record<string, any>) => void,
): IValidationControllerProps<TValue> => {
  return {
    onValueChange: value => {
      updateProps({ value });
    },
    setValidityIndication: shouldShowValidityIndication => {
      updateProps({ shouldShowValidityIndication });
    },
  };
};
