import type { IComponentController } from '@wix/editor-elements-types/thunderbolt';
import { getValidationControllerProps } from '../../../core/commons/controllerUtils';
import { ITextAreaInputControllerActions } from '../TextAreaInput.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): ITextAreaInputControllerActions => ({
  ...getValidationControllerProps(updateProps),
});

export default mapActionsToProps;
